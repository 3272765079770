// Angular Core Modules
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// External Modules
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, debounceTime, filter, fromEvent, map } from 'rxjs';

// Models
import { EasyDataEntry, MultiSearchConfig } from './model/multi-search.model';
import { ICriteriaSearchResponse, IKeyValue } from '../models/ref-data/criteria-search.model';

// Services
import { MultiSearchService } from './service/multi-search.service';

// Component decorator
@Component({
  selector: 'app-multi-search',
  templateUrl: './multi-search.component.html',
  styleUrls: ['./multi-search.component.scss'],
  providers: [NgbDropdownConfig],
})
export class MultiSearchComponent implements OnInit, OnDestroy {
  @Input() multiSearchConfig!: MultiSearchConfig;
  @Output() selectedOption = new EventEmitter<IKeyValue[]>();

  // Private Members
  private readonly BULK_DATA_LIMIT: number = 1024;
  private readonly subscription = new Subscription();
  // Default configuration goes here
  private debounceTime: number = 1000;
  public restrictedChars: number = 2;

  // Public Members
  public easyDataEntry: EasyDataEntry = {
    isopen: false,
    isBulkUpload: false,
    isBulkUploadSearch: false,
    bulkUploadArr: [],
    selectedCodes: [],
    inValidArr: [],
    currentKey: 0,
    currentIndex: 0,
    resolveIndex: 0,
    filteredResults: [],
    isFixed: true,
    showInvalidPageCodes: false,
    loader: false,
  };

  public showNoResult: boolean = false;
  public searchQuery: string = '';
  public searchQueryFix: string = '';
  public isFocus: boolean = false;

  @Input() idPrefix!: string;
  @Input() filterDef!: any;
  @ViewChild('multiEntryDropdown', { static: false }) multiEntryDropdown!: NgbDropdown;
  @ViewChild('searchBox', { static: true }) searchInput!: ElementRef;
  constructor(private readonly multiSearchService: MultiSearchService) { }

  ngOnInit() {
    this.debounceTime = this.multiSearchConfig?.debounceTime || this.debounceTime;
    this.restrictedChars = this.multiSearchConfig?.restrictedCharsTo || this.restrictedChars;
    this.multiSearchConfig.selectedAttribute = this._configEasyDataEntry(this.multiSearchConfig.selectedAttribute);
    this.search();
  }

  private search() {
    this.subscription.add(fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(this.debounceTime),
        filter((data) => {
          this.easyDataEntry.isopen = false;
          const searchTerm = data && data.trim();
          const isValidSearchTerm = searchTerm.length > this.restrictedChars;
          this.easyDataEntry.loader = isValidSearchTerm;
          this.easyDataEntry.filteredResults = [];
          return isValidSearchTerm && searchTerm;
        }),
      )
      .subscribe({
        next: () => {
          this.getMultiSearchCodes();
        },
      }));
  }

  closeDropdown() {
    this.easyDataEntry.filteredResults = [];
    this.easyDataEntry.loader = false;
    this.easyDataEntry.isopen = false;
  }

  getMultiSearchCodes() {
    this.easyDataEntry.filteredResults = [];
    this.easyDataEntry.isopen = false;
    if (this.searchQuery && this.searchQuery.length > 2) {
      this.easyDataEntry.selectedCodes = [];
      this.easyDataEntry.loader = true;
      if (this.searchQuery.includes(';')) {
        this.easyDataEntry.isBulkUpload = true;
        this.easyDataEntry.isBulkUploadSearch = true;
        this.easyDataEntry.showInvalidPageCodes = false;
        const splitArr = this.removeWhiteSpace(this.searchQuery.split(';'));
        this.easyDataEntry.bulkUploadArr = splitArr;
        this.easyDataEntry.selectedCodes = splitArr.filter(item => item && item.toString().trim().length).slice(0, this.BULK_DATA_LIMIT);
      } else {
        this.easyDataEntry.selectedCodes.push(this.searchQuery);
        this.easyDataEntry.isBulkUploadSearch = false;
      }
      if (this.multiSearchConfig.options?.isApiCallCancelled) {
        this.codeHandler();
      } else {
        this.subscription.add(this.multiSearchService.getMultiSelectValuesOnSearch<ICriteriaSearchResponse>(this.multiSearchConfig.searchKey, this.easyDataEntry.selectedCodes, this.easyDataEntry.isBulkUploadSearch)
          .subscribe({
            next: (data: ICriteriaSearchResponse) => {
              if (data) {
                if (this.easyDataEntry?.isBulkUploadSearch) {
                  this.updateSelectedValues(data);
                } else {
                  this.easyDataEntry.filteredResults = data?.results;
                  this.easyDataEntry.isopen = true;
                  this.showNoResult = this.easyDataEntry?.filteredResults?.length === 0;
                }
              } else {
                this.easyDataEntry.isopen = true;
                this.showNoResult = true;
                this.easyDataEntry.filteredResults = [];
              }
            }, error: (e: HttpErrorResponse) => {
              this.easyDataEntry.isopen = true;
              this.showNoResult = true;
              this.easyDataEntry.filteredResults = [];
              this.easyDataEntry.loader = false;
              console.log(`<MultiSearchComponent> - <getMultiSearchCodes> Error occurred during getMultiSelectValuesOnSearch...`, e);
            }, complete: () => {
              this.easyDataEntry.loader = false;
            },
          }));
      }
    } else {
      this.easyDataEntry.filteredResults = [];
      this.easyDataEntry.loader = false;
    }
  }

  private codeHandler() {
    this.easyDataEntry.filteredResults = [];
    this.easyDataEntry.loader = false;
    const response:ICriteriaSearchResponse = {
      results: this.codeValidate(this.easyDataEntry.selectedCodes),
    };
    if (this.easyDataEntry.isBulkUploadSearch) {
      this.updateSelectedValues(response);
    } else {
      this.easyDataEntry.filteredResults = response.results;
      this.easyDataEntry.isopen = true;
    }
  }

  private codeValidate(selectedCodes: string[]):IKeyValue[] {
    const regEx = /^[0-9]{3,5}$/;
    // transform data set to results
    return selectedCodes.filter((item: string) => {
      return regEx.test(item);
    }).map((code: string) => {
      return {
        key: code,
        key_as_string: code,
      };
    });
  }

  getFilteredMultiSearchCodes(item: IKeyValue) {
    const itemExist = this.multiSearchConfig.selectedAttribute.filter((x: IKeyValue) => x.key === item.key)[0];
    if (!itemExist) {
      if (this.easyDataEntry.isBulkUpload) {
        this.easyDataEntry.inValidArr = this.easyDataEntry.inValidArr.filter((invalidItem: any) => {
          if (invalidItem.key === this.easyDataEntry.currentKey) {
            this.multiSearchConfig.selectedAttribute.splice(this.easyDataEntry.currentIndex, 1);
          }
          return invalidItem.key !== this.easyDataEntry.currentKey;
        });
        this.multiSearchConfig.selectedAttribute.splice(this.easyDataEntry.currentIndex, 0, {
          key: item.key,
          key_as_string: item.key_as_string,
          isValid: true,
        });
      } else {
        this.multiSearchConfig.selectedAttribute.push({
          key: item.key,
          key_as_string: item.key_as_string,
          isValid: true,
        });
      }
      if (this.multiSearchConfig.options.areaMove) this.clearSearch();
    } else {
      const index = this.multiSearchConfig.selectedAttribute.findIndex((x: IKeyValue) => x.key === item.key);
      this.multiSearchConfig.selectedAttribute.splice(index, 1);
    }
    if (!this.easyDataEntry.isopen) this.clearSearch();
    this.selectedOption.emit(this.multiSearchConfig.selectedAttribute);
    this.getNextActiveElement();
  }

  private _configEasyDataEntry(selectedValues: IKeyValue[]): IKeyValue[] {
    this._initEasyDataObj();
    return selectedValues.map((item: IKeyValue) => ({
      key: item.key,
      key_as_string: item.key_as_string,
      isValid: true,
    }));
  }

  private _initEasyDataObj() {
    this.easyDataEntry = {
      isopen: false,
      isBulkUpload: false,
      isBulkUploadSearch: false,
      bulkUploadArr: [],
      selectedCodes: [],
      inValidArr: [],
      currentKey: 0,
      currentIndex: 0,
      resolveIndex: 0,
      filteredResults: [],
      isFixed: true,
      showInvalidPageCodes: false,
      loader: false,
    };

  }

  filterSelectedAttribute(key: string): any[] {
    return this.multiSearchConfig.selectedAttribute.filter((item: IKeyValue) => item.key === key);
  }

  isSelected(key: string): boolean {
    const index = this.multiSearchConfig.selectedAttribute.findIndex((item: IKeyValue) => item.key === key);
    return index > -1;
  }

  private updateSelectedValues(result: ICriteriaSearchResponse) {
    this.easyDataEntry.selectedCodes.forEach((item: string) => {
      const resp = {
        key: item,
        key_as_string: '',
        isValid: false,
      };
      const filteredArr = result.results.find((obj: IKeyValue) => obj.key === item);
      if (filteredArr) {
        resp.key_as_string = filteredArr.key_as_string;
        resp.isValid = true;
      } else {
        const errorExist = this.easyDataEntry.inValidArr.filter((invalidItem: IKeyValue) => invalidItem.key === resp.key)[0];
        if (!errorExist) {
          this.easyDataEntry.inValidArr.push(resp);
        }
        this.easyDataEntry.isFixed = false;
      }
      const itemExist = this.multiSearchConfig.selectedAttribute.filter((x: IKeyValue) => x.key === resp.key)[0];
      if (!itemExist) {
        this.multiSearchConfig.selectedAttribute.push(resp);
      }
    });
    this.searchQuery = '';
    this.easyDataEntry.filteredResults = [];
    this.selectedOption.emit(this.multiSearchConfig.selectedAttribute);
  }

  private removeWhiteSpace(source: string[]) {
    return source.map((obj: string) => obj.trim());
  }

  private getNextActiveElement() {
    if (this.easyDataEntry.isBulkUploadSearch && this.easyDataEntry.inValidArr.length > 0) {
      if (this.easyDataEntry.resolveIndex >= this.easyDataEntry.inValidArr.length) {
        this.easyDataEntry.resolveIndex = this.easyDataEntry.inValidArr.length - 1;
      }
      const nextActiveId = this.easyDataEntry.inValidArr[this.easyDataEntry.resolveIndex].key;
      this.searchQuery = nextActiveId;
      this.easyDataEntry.currentIndex = this.multiSearchConfig.selectedAttribute.findIndex((obj: IKeyValue) => obj.key === nextActiveId);
      this.easyDataEntry.currentKey = nextActiveId;
      document.getElementById(nextActiveId)?.classList.add('active');
    }
  }

  public removeSelectedItem(obj: IKeyValue) {
    const index = this.multiSearchConfig.selectedAttribute.indexOf(obj);
    this.multiSearchConfig.selectedAttribute.splice(index, 1);
    this.selectedOption.emit(this.multiSearchConfig.selectedAttribute);
  }

  // Below Functions are for fixing the invalid codes in multi-search, Will add it later
  public updateSelectedItem(obj: any, index: number) {
    if (!obj.isValid) {
      this.searchQuery = obj.key;
      this.easyDataEntry.currentIndex = index;
      this.easyDataEntry.currentKey = obj.key;
      this.easyDataEntry.isBulkUpload = true;
      document.getElementById(obj.key)?.classList.add('active');
      this.searchQueryFix = this.searchQuery;
      this.getMultiSearchCodes();
    }
  }

  public fixInvalidCodes() {
    this.easyDataEntry.showInvalidPageCodes = false;
    if (this.easyDataEntry.inValidArr && this.easyDataEntry.inValidArr.length > 0) {
      this.easyDataEntry.showInvalidPageCodes = true;
      const keyToSearch = this.easyDataEntry.inValidArr[this.easyDataEntry.resolveIndex];
      const matchedIndex = this.multiSearchConfig.selectedAttribute.findIndex((obj: IKeyValue) => obj.key === keyToSearch.key);
      this.updateSelectedItem(this.easyDataEntry.inValidArr[this.easyDataEntry.resolveIndex], matchedIndex);
    }
  }

  public changeResolve(obj: any) {
    if (!obj.inValid) {
      this.easyDataEntry.resolveIndex = this.easyDataEntry.inValidArr.findIndex((invalidObj: IKeyValue) => invalidObj.key === obj.key);
    }
  }

  public openDropdown($event: Event) {
    $event.stopImmediatePropagation();
    if (!this.easyDataEntry.isopen)
      this.multiEntryDropdown.open();
  }

  public clearSearch(): void {
    if (this.searchQuery.length > this.restrictedChars) {
      this.searchQuery = '';
      this.searchInput.nativeElement.value = '';
      this.easyDataEntry.filteredResults = [];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
