<div class="row g-0" *ngIf="showTabComponent">
  <div class="col-12 position-relative">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav nav-tabs pt-1" [ngClass]="tabConfig?.tabWrapperClass">
      <!-- Tab Node -->
      <ng-template #tabTemplateWtState let-tab="tab">
        <li>
          <ul class="px-0 d-flex align-items-center justify-content-center">
            <li [attr.id]="tab.id" [ngbNavItem]="tab.id" *ngIf="tab?.type === 'tab'" class="nav-item px-0" [ngClass]="tab?.tabCssClass">
              <a ngbNavLink href="javascript:void(0)" class="m-0" [ngClass]="tab?.cssClass" routerLinkActive="active" [routerLink]="tab.stateName" [queryParams]="tab?.queryParams">
                {{ tab.label }}
                <i class="mh-material-icons" *ngIf="tab.isRemovable" (click)="removeTab($event, tab)">
                  <span class="visually-hidden">Close</span>
                  close
                </i>
              </a>
            </li>
            <!-- Badge Support Eg. New! Release for tableau reporting -->
            <ng-template [ngIf]="tab.badge && tab.badge?.permissionScope" [ngxPermissionsOnly]="tab?.badge?.permissionScope">
              <li class="d-inline-block">
                <span class="badge badge-light px-3 py-2 font-size-14 position-absolute rounded" ngbTooltip="{{tab?.badge?.message}}" placement="top" tooltipClass="{{tab?.badge?.tooltipClass}}">{{tab?.badge?.text}}</span>
              </li>
            </ng-template>
          </ul>
        </li>
      </ng-template>
      <ng-template #tabTemplateWoState let-tab="tab">
        <li [ngbNavItem]="tab.id" *ngIf="tab?.type === 'tab' || tab?.type === 'link'" [attr.id]="tab.id" class="nav-item px-0" [ngClass]="tab?.tabCssClass">
          <a ngbNavLink href="javascript:void(0)" class="m-0" [ngClass]="tab?.cssClass">
            <ng-template [ngIf]="tab.enumeration">
              <span [attr.id]="tab.enumeration.id" class="circle text-center font-size-12 p-1">{{tab.enumeration.label}}</span> 
            </ng-template>
            {{ tab.label }}
            <i class="mh-material-icons" *ngIf="tab.isRemovable" (click)="removeTab($event, tab?.label)">
              <span class="visually-hidden">Close</span>
              close
            </i>
            <i class="mh-material-icons font-size-17 text-danger pb-2" *ngIf="tab?.isWarning"><span
              class="visually-hidden">warning</span>warning</i>
          </a>
        </li>
      </ng-template>
      <!-- Tabs with stateName -->
      <ng-template *ngFor="let tab of tabConfig?.options" [ngIf]="tab?.stateName">
        <ng-container *ngIf="tab.permissionScope">
          <ng-container [ngTemplateOutlet]="tabTemplateWtState" [ngTemplateOutletContext]="{tab}" *ngxPermissionsOnly="tab.permissionScope" >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!tab.permissionScope">
          <ng-container [ngTemplateOutlet]="tabTemplateWtState" [ngTemplateOutletContext]="{tab}">
          </ng-container>
        </ng-container>
      </ng-template>
      <!-- Tabs without stateName -->
      <ng-template *ngFor="let tab of tabConfig?.options" [ngIf]="!tab?.stateName">
        <ng-container *ngIf="tab.permissionScope">
          <ng-container [ngTemplateOutlet]="tabTemplateWoState" [ngTemplateOutletContext]="{tab}" *ngxPermissionsOnly="tab.permissionScope" >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!tab.permissionScope">
          <ng-container [ngTemplateOutlet]="tabTemplateWoState" [ngTemplateOutletContext]="{tab}">
          </ng-container>
        </ng-container>
      </ng-template>
      <!-- Sorted by label -->
      <div *ngIf="tabConfig?.showSorter && tabConfig?.showSorter?.display" class="font-size-12 ms-auto d-flex align-items-center">
        <span>{{tabConfig?.showSorter?.sortedByLabel}} {{tabConfig?.showSorter?.DateCreatedLabel}}</span>
      </div>
    </ul>
  </div>
</div>