<div class="row" [attr.id]="idPrefix ? idPrefix+'-multi-entry-panel' : 'multi-entry-panel'">
	<div class="col-12 multi-entry-container">
		<div class="form-group mb-0" [ngClass]="multiSearchConfig.options.cssClass?.includes('mt-3') ? 'mt-3' : 'mt-0' ">
			<ng-container *ngIf="multiSearchConfig.inputLabel !== ''">
				<label class="form-label">{{ multiSearchConfig.inputLabel }}
					<span class="font-size-11 mb-0 pt-1 ps-1 d-inline-block">(max 1024 codes)</span>
				</label>
			</ng-container>
			<div ngbDropdown #multiEntryDropdown="ngbDropdown" class="multi-search-panel position-relative"
				[dropdownClass]="'msearch-dropdown'" [autoClose]="'outside'" [(open)]="easyDataEntry.isopen" (openChange)="clearSearch()" >
				<div class="form-group mb-0 d-flex flex-row">
					<textarea [attr.aria-label]="multiSearchConfig.inputConfig" #searchBox [(ngModel)]="searchQuery" (ngModelChange)="easyDataEntry.loader = searchQuery.length > 2" class="form-control border-radius-2"
						[attr.id]="idPrefix ? idPrefix+'-searchbox' : 'searchbox'"
						(click)="openDropdown($event)" (focus)="openDropdown($event)"
						(keydown)="openDropdown($event)"
						placeholder={{multiSearchConfig.inputConfig}}
						rows="1" [name]="idPrefix ? idPrefix+'-searchbox' : 'searchbox'"></textarea>
					<span class="spinner-border spinner-border-sm position-absolute" [attr.id]="idPrefix ? idPrefix+'-searchbox-spinner' : 'searchbox-spinner'"
						*ngIf="easyDataEntry.loader && !multiSearchConfig.options.areaMove"></span>
					<span *ngIf="searchQuery.length && !easyDataEntry.loader" class="mh-material-icons form-control-feedback position-absolute clear-search"
						(click)="clearSearch()">
						<a *ngIf="!multiSearchConfig.options.areaMove" href="javascript:void(0)" class="link-secondary text-decoration-none" [attr.id]="idPrefix ? idPrefix+'-cancel' : 'cancel'">
						  <i class="bi-x-circle-fill"></i>
						</a>
					</span>
				</div>
				<ul ngbDropdownMenu class="dropdown-menu w-100 font-size-14 border-1" role="menu"
					aria-labelledby="btn-keyboard-nav"
					[ngClass]="{ 'd-none': (easyDataEntry.filteredResults === [] || searchQuery.length < 3) }">
					<li *ngFor="let result of easyDataEntry.filteredResults" class="dropdown-item border-bottom"
						(click)="getFilteredMultiSearchCodes(result)">
						<div class="d-flex justify-content-between align-items-center">
							<div class="font-size-14 text-wrap" (click)="easyDataEntry.isopen=false">
								{{ (result.key === result.key_as_string) ? result.key_as_string :
								""+result.key_as_string}}
								<p class="font-size-12 py-1 fw-bold" *ngIf="multiSearchConfig.options.showKey || multiSearchConfig.options.areaMove">
									{{ (result.key === result.key_as_string) ? result.key_as_string : ""+result.key}}
								</p>
							</div>
							<div class="d-flex justify-content-end align-items-center ps-3">
								<img [src]="filterSelectedAttribute(result.key) && isSelected(result.key) ? 'assets/images/legacy/checkmark-circle-icomoon.svg' : 'assets/images/legacy/arrow-right-circle-icomoon-copy.svg'"
									alt="select-icon">
							</div>
						</div>
					</li>
					<li class="disabled dropdown-item no-data fw-lighter" role="menuitem"
						*ngIf="!easyDataEntry.loader && searchQuery.length && !easyDataEntry.filteredResults.length">
						No record found
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<ng-content></ng-content>
<div class="row g-0 easy-ta preview-selected" [ngClass]="multiSearchConfig.options.cssClass" [attr.id]="idPrefix ? idPrefix+'-preview-selection' : 'preview-selection'" *ngIf="multiSearchConfig.selectedAttribute.length > 0">
	<div class="col-12 py-3 items-container" *ngIf="multiSearchConfig.selectedAttribute.length > 0">
		<div id="{{ multiSearchConfig.parentElementId }}" class="d-inline-block">
			<div class="d-inline-block easy-data-tag item-block px-1 py-2" id="{{ item.key }}"
				*ngFor="let item of multiSearchConfig.selectedAttribute; let i = index"
				[ngClass]="{'invalid': (!item.isValid), 'active': (searchQuery === item.key && !item.isValid)}"
				(click)="changeResolve(item); updateSelectedItem(item, i)"
				[ngbTooltip]="item.key_as_string && item.key_as_string.length > 0 ? tooltipContent : null" placement="top" triggers="hover"
				container="body">
				<ng-template #tooltipContent>
					<span *ngIf="item.key_as_string && (item.key===item.key_as_string)">{{ item.key }}</span>
					<span *ngIf="!(item.key_as_string && (item.key===item.key_as_string))"><b>{{ item.key }}: </b>{{
						item.key_as_string}}</span>
				</ng-template>
				<span class="d-inline-block font-size-14 key">{{multiSearchConfig.options.displayKey==='key_as_string' ? item.key_as_string: (item.key ? item.key : item) }}</span>
				<a href="javascript:void(0);" aria-label="Close Button"
					*ngIf="item.isValid || item.isValid === undefined" (click)="removeSelectedItem(item)">
					<i class="p-1 bi bi-x close-select close-icon font-size-18 align-middle">
						<span class="visually-hidden">Close</span>
					</i>
				</a>
				<i *ngIf="item.isValid === false" class="p-1 bi-exclamation-triangle-fill font-size-14 align-middle">
					<span class="visually-hidden">Warning</span>
				</i>
			</div>
		</div>
	</div>
	<div class="warning-message-container d-flex" *ngIf="easyDataEntry.inValidArr.length > 0">
		<div class="width-80 py-2 padding-right-2 font-size-14 padding-left-20"
		  *ngIf="!easyDataEntry.showInvalidPageCodes">
		  <i class="p-1 bi bi-exclamation-triangle-fill font-size-15 text-danger">
			<span class="visually-hidden">Warning</span>
		  </i>
		  <span class="fw-bold">{{ easyDataEntry.inValidArr.length }} failed to resolve.</span>
		  These will be removed after saving unless you fix them.
		</div>
		<button class="width-20 text-white font-size-14 fw-bold cursor-pointer"
		  (click)="easyDataEntry.resolveIndex = 0; fixInvalidCodes()" *ngIf="!easyDataEntry.showInvalidPageCodes">Fix now
		</button>
		<button class="cursor-pointer width-10" [disabled]="easyDataEntry.resolveIndex === 0"
		  (click)="easyDataEntry.resolveIndex = easyDataEntry.resolveIndex - 1; fixInvalidCodes();"
		  *ngIf="easyDataEntry.showInvalidPageCodes">
		  <i class="bi bi-chevron-left text-white font-size-20"></i>
		</button>
		<div class="py-2 font-size-14 width-80 d-flex align-items-center" *ngIf="easyDataEntry.showInvalidPageCodes">
		  <span class="ms-2">Resolving {{ easyDataEntry.resolveIndex + 1 }} of {{ easyDataEntry.inValidArr.length
			}}</span>
		</div>
		<button class="cursor-pointer width-10"
		  [disabled]="easyDataEntry.resolveIndex === (easyDataEntry.inValidArr.length - 1)"
		  (click)="easyDataEntry.resolveIndex = easyDataEntry.resolveIndex + 1; fixInvalidCodes();"
		  *ngIf="easyDataEntry.showInvalidPageCodes">
		  <i class="bi bi-chevron-right text-white font-size-20"></i>
		</button>
	</div>
</div>
